@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@700&display=swap');

/* to change color from white is in getstatus */ 

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  background-color:#111;
  padding: 0;
  /*  display: flex; idk if this works ---- This is why i couldnt get content to align */ 
  color: white;
  background-image: url("background10.png");
  background-repeat: no-repeat;
  max-width: 600px; 
  margin: 0 auto; 
}
p { 
  margin: 0px;
  padding: 0px;
} 
#title{
  background-color: black;
}
.Minter {
  background-color:black;
}
#introduction {
  padding: 20px;
  background-color: #999;
  color: black;
}
#rarity { /* V A R I A T I O N S */ 
  border: 10px solid #999;
  background-color: #111;
}
#provenance { /* PROVENANCE */ 
  border: 10px solid #999;
  background-color: #111;
}
#community { /* COMMUNITY */ 
  border: 10px solid #999;
  background-color: #111;
}
#disclaimer { /* disclaimer */ 
  border: 5px solid #999;
  background-color: #111;
}
#border {
  border: 5px solid #999;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#link { 
  color:#999;
  
}
.hovfont {
  font-family: 'Cinzel Decorative', cursive ;
}
#statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
#statbutton {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 8px;
  font-size: 10px;
  margin: 1px 1px;
  cursor: pointer;
  transition-duration: 0.8s;
}
#statbutton:hover {
  background-color: #999;
  color:#111;
  border: 6px solid #111;
}
#button {
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #999;
  border: 4px solid #999;
  padding: 18px;
  font-size: 20px;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;

}
#button:hover {
  background-color: #999;
  color:#111;
}
#buttonspecial { 
  font-family: 'Press Start 2P', cursive;
  background-color: #111;
  color: #FFF;
  border: 2px solid #fff;
  padding: 18px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.8s;
}
#buttonspecial:hover {
  background-color: #FFF;
  color:#111;
}
h1 { 
  font-size: 40px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 50px;
  line-height: 16px;
  background-color: black;
  color:#999;
  padding: 18px;

}
#supermint {
}
#ladydescription{
  text-align: center;
  content-align: center;
}
/*
#walletButton:focus {
  outline:none;
  border: none;
}

#walletButton:hover {
  outline:none;
  border :none;
} */

#mintButton {
  margin-top: 40px;
  background-color: black;
  color:white;
}
#nft {
  width: 400px;
  height: 400px;
  text-align: center;
}
#nftmain {
  width: 400px;
  height: 400px;
  text-align: center;
  border: none;
}